'use client'

import { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import dayjs from 'dayjs'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Typography, IconButton, CardContent, Card, CardActions, Button } from '@mui/material'
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon, ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material'

// Types
import type { APIArticleReadData } from '@/types/api/article/read'
import type { JobItem, JobsProps } from '@/types/components/molecules/jobs'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Utils
import TransText from '@/utils/trans-text'

// Componenets
const TextAtom = dynamic(() => import('@/components/atoms/text'))

const JobsMolecule = (props: JobsProps) => {
	// Props
	const { items, description, showAll, maxWidth } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [show, setShow] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [findItems, setFindItems] = useState<JobItem[]>(items ?? [])

	// Check description
	if (description) {
		let checkItems: JobItem[] = []
		let finalDescription: string = description || ''

		// Find
		const result = finalDescription.match(/<li[^>]+>(.*?)(?=<\/li>)/g)

		if (result && result.length > 0) {
			result.map(item => {
				const titleResult = /<span[^>]+>(.*?)<\/span>/g.exec(item)

				if (titleResult && titleResult.length) {
					if (checkItems.length) {
						if (checkItems[checkItems.length - 1].date === undefined) checkItems[checkItems.length - 1].date = titleResult[1]
						else if (checkItems[checkItems.length - 1].closes === undefined) checkItems[checkItems.length - 1].closes = titleResult[1]
						else if (checkItems[checkItems.length - 1].department === undefined) checkItems[checkItems.length - 1].department = titleResult[1]
						else if (checkItems[checkItems.length - 1].description === undefined) checkItems[checkItems.length - 1].description = titleResult[1]
						else checkItems.push({ title: titleResult[1] })
					} else checkItems.push({ title: titleResult[1] })
				}
			})
		}

		setFindItems([...findItems, ...checkItems])
	}

	// Callbacks
	const readItems = useCallback(async () => {
		setLoading(true)
		// Result
		const response = await fetch(`/api/article/read?search=4&type=category&limit${showAll ? 100 : 5}&skip=0`)
		const result: APIArticleReadData = await response.json()
		setLoading(false)

		if (result.status && result.data && result.data.items) {
			setFindItems(
				result.data.items.map(item => {
					// Find
					const { text, components } = TransText(item.description || '')
					let findDate = ''
					let findCloses = ''
					let department = ''

					if (components.length)
						components.map(component => {
							if (component.type === 'date') {
								if (!findDate) findDate = component.description.replace('<p className="editor-paragraph"><span >', '').replace('</span></p>', '')
								else findCloses = component.description.replace('<p className="editor-paragraph"><span >', '').replace('</span></p>', '')
							} else if (component.type === 'department')
								department = component.description.replace('<p className="editor-paragraph"><span >', '').replace('</span></p>', '')
						})

					return {
						title: item.title.toString(),
						date: findDate,
						closes: findCloses,
						department,
						description: text,
						link: `/jobs/${item.alias}`
					}
				})
			)
		}
	}, [showAll])

	useEffect(() => {
		if (loading && !description) readItems()
	}, [loading, description, readItems])

	return (
		<Container maxWidth={maxWidth} sx={{ py: { xs: 4, md: 16 }, position: 'relative' }}>
			{findItems.length ? (
				<>
					<Grid position="relative" sx={{ zIndex: 2 }}>
						<Grid container direction="row-reverse" justifyContent="space-between" alignItems="center" spacing={{ sm: 4 }} mb={{ md: 2 }}>
							<Grid item xs={12} md={6} mb={2}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h6" component="h6" fontSize={{ xs: 14, md: 16 }} fontWeight={700} textTransform="uppercase" color="#014178">
											{t('common:title.business')}
										</Typography>
									</Grid>

									<Grid item xs={12} md={6}>
										<Typography
											variant="h3"
											component="h3"
											fontSize={{ xs: 24, md: 48 }}
											fontWeight={700}
											color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
										>
											{t('common:title.employmentOpportunities')}
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} md={6} mb={2}>
								<Grid container spacing={1}>
									<Grid item>
										<IconButton
											size="large"
											onClick={() => {
												setShow((show - 1 + 4) % 4)
											}}
											sx={{
												color: theme.palette.secondary.main,
												backgroundColor: alpha(theme.palette.secondary.main, 0.1),
												'&:hover': {
													color: '#f2f2f2',
													backgroundColor: alpha(theme.palette.secondary.main, 0.4)
												}
											}}
										>
											<ArrowBackIosNewIcon />
										</IconButton>
									</Grid>

									<Grid item>
										<IconButton
											size="large"
											onClick={() => {
												setShow((show + 1) % 4)
											}}
											sx={{
												color: theme.palette.secondary.main,
												backgroundColor: alpha(theme.palette.secondary.main, 0.1),
												'&:hover': {
													color: '#f2f2f2',
													backgroundColor: alpha(theme.palette.secondary.main, 0.4)
												}
											}}
										>
											<ArrowForwardIosIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container direction="row-reverse" justifyContent="space-between" spacing={{ sm: 4 }}>
							<Grid item xs={12} sm={6}>
								<Card
									sx={{
										mb: 2,
										p: 2,
										borderRadius: 1,
										boxShadow: `0px 12px 40px 0px ${alpha(theme.palette.grey[900], 0.04)}`
									}}
								>
									<CardContent>
										{findItems[show].title && (
											<Typography
												mb={{ xs: 1, md: 2 }}
												fontSize={36}
												fontWeight={700}
												color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
											>
												{findItems[show].title}
											</Typography>
										)}

										{(findItems[show].date || findItems[show].closes || findItems[show].department) && (
											<Grid mb={{ xs: 1, md: 2 }} p={{ xs: 3, md: 5 }} sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1), borderRadius: 1 }}>
												<Grid container justifyContent="space-between">
													{findItems[show].date && (
														<Grid item>
															<Typography variant="body2" fontSize={20} fontWeight={700} color={theme.palette.primary.dark}>
																{t('common:title.posted')}
															</Typography>

															<Typography variant="body2" fontSize={14} fontWeight={300} color={theme.palette.primary.dark}>
																{dayjs(findItems[show].date).format('MMM DD, YYYY').toString()}
															</Typography>
														</Grid>
													)}

													{findItems[show].closes && (
														<Grid item>
															<Typography variant="body2" fontSize={20} fontWeight={700} color={theme.palette.primary.dark}>
																{t('common:title.closes')}
															</Typography>

															<Typography variant="body2" fontSize={14} fontWeight={300} color={theme.palette.primary.dark}>
																{dayjs(findItems[show].closes).format('MMM DD, YYYY').toString()}
															</Typography>
														</Grid>
													)}

													{findItems[show].department && (
														<Grid item>
															<Typography variant="body2" fontSize={20} fontWeight={700} color={theme.palette.primary.dark}>
																{t('common:title.dept')}
															</Typography>

															<Typography variant="body2" component="span" fontSize={14} fontWeight={300} color={theme.palette.primary.dark}>
																<TextAtom>{findItems[show].department}</TextAtom>
															</Typography>
														</Grid>
													)}
												</Grid>
											</Grid>
										)}

										{findItems[show].description && (
											<Typography variant="body2" component="span" fontSize={14} fontWeight={400} color="#7d7d84">
												<TextAtom>{findItems[show].description}</TextAtom>
											</Typography>
										)}
									</CardContent>

									<CardActions sx={{ justifyContent: 'end' }}>
										{findItems[show].link && (
											<Link href={findItems[show].link ?? '#'}>
												<Button>{t('form:button.fullDetails')}</Button>
											</Link>
										)}
									</CardActions>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6}>
								{findItems.map((item, index) => {
									if (index < 4)
										return (
											<Card
												key={index}
												onClick={() => {
													setShow(index)
												}}
												sx={{
													mb: 2,
													borderRadius: 1,
													boxShadow: `0px 12px 40px 0px ${alpha(theme.palette.common.black, 0.04)}`,
													transition: 'all .3s ease',
													'&:hover': {
														cursor: 'pointer',
														boxShadow: `0px 8px 20px 0px ${alpha(theme.palette.common.black, 0.1)}`
													}
												}}
											>
												<CardContent>
													{item.title && (
														<Typography
															fontSize={24}
															fontWeight={700}
															color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
														>
															{item.title}
														</Typography>
													)}

													{item.date && (
														<Typography variant="body2" fontSize={12} fontWeight={400} color={theme.palette.primary.dark}>
															{dayjs(item.date).format('MMM DD, YYYY').toString()}
														</Typography>
													)}
												</CardContent>
											</Card>
										)
								})}
							</Grid>
						</Grid>

						{!showAll && (
							<Grid container mt={2}>
								<Link href="/jobs">
									<Button endIcon={<ArrowRightAltIcon />} sx={{ color: '#ed1651' }}>
										{t('form:button.allOpenings')}
									</Button>
								</Link>
							</Grid>
						)}
					</Grid>
				</>
			) : null}
		</Container>
	)
}

export default JobsMolecule
